/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import {
  Typography, InputLabel,
  Card, List, ListItem, Button,
  Modal, TextField, Select,
  FormControl,
} from '@material-ui/core';

import styles from './ResourcePartnerProgram.module.scss';
import Layout from '../../components/Layout';

const possibleRoles = [
  'Sourcer/Recruiter',
  'Recruiting Coordinator',
  'Hiring Manager',
  'Head of Talent/Head of People ',
  'CxO',
  'Other',
];
const possibleAts = [
  'Bullhorn',
  'Greenhouse',
  'Lever',
  'Other',
  'None',
];

const ResourcePartnerProgram = ({
  location
}) => {
  const [open, setOpen] = useState(false);
  const [ats, setAts] = useState();

  return (
    <Layout
      location={location}
      showHeader={false}
      showFooter={false}
      disableContainer
    >
      <div className={styles.container}>
        <div className={styles.topSection}>
          <img
            className={styles.logo}
            src="/images/brand/white/logo.svg"
            alt="Resource Horizontal Logo"
          />
          <Typography variant="h3">
            Deliver the best candidate experience in the world with us.
          </Typography>
          <Typography variant="p">
            The <strong>Resource Partner Program</strong> was created to give
            leading talent teams a way to
            influence the products they want to see on the market.
            By joining our partner program you
            will be the first (!!) to test our game-changing
            candidate experience
            platform with a select group of your peers.
          </Typography>
        </div>
        <div className={styles.infoSection}>
          <img 
            className={styles.bannerImage}
            src="/images/uploads/automation.png"
            alt="Info-graphic of candidate being reached out to virtually"
          />
          <Card className={styles.infoCard}>
            <Typography
              variant='h4'
            >
              Annnnd you’ll get some other super cool things like:
            </Typography>
            <List>
              <ListItem>• Priority access to our support team</ListItem>
              <ListItem>• The first use of Resource Guides™</ListItem>
              <ListItem>• Direct influence on emerging products</ListItem>
              <ListItem>• Preferred pricing</ListItem>
              <ListItem>• Co-marketing opportunities</ListItem>
            </List>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(true)}
            >
              Apply to the Resource Partner Program
            </Button>
          </Card>
        </div>
        <Modal
          className={styles.modalCont}
          aria-labelledby="Get Started Form"
          aria-describedby="Fill out this form to get started with resource"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Card
            className={styles.card}
          >
            <Typography variant="h4">
              Resource Partner Program
            </Typography>
            <form action="https://go.pardot.com/l/823443/2020-01-08/v66" method="post">
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                />
                <TextField
                  required
                  className={styles.input}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  name="cell"
                  label="Cell"
                  type="tel"
                  placeholder="3603452345"
                  variant="outlined"
                />
                <TextField
                  required
                  className={styles.input}
                  name="companyName"
                  label="Company Name"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <TextField
                  required
                  className={styles.input}
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                />
              </div>
              <div className={styles.row}>
                <FormControl
                  className={styles.selectCont}
                  variant="outlined"
                  required
                >
                  <InputLabel
                    className={styles.selectLabel}
                    id="role-label"
                  >
                    Title
                  </InputLabel>
                  <Select
                    native
                    name="role"
                    labelId="role-label"
                  >
                    <option value="" />
                    {possibleRoles.map((role) => (
                      <option value={role}>
                        {role}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={styles.row}>
                <FormControl
                  className={styles.selectCont}
                  variant="outlined"
                  required
                >
                  <InputLabel
                    className={styles.selectLabel}
                    id="ats-label"
                  >
                    ATS
                  </InputLabel>
                  <Select
                    native
                    name="ats"
                    labelId="ats-label"
                    value={ats}
                    onChange={(event) => setAts(event.target.value)}
                  >
                    <option value="" />
                    {possibleAts.map((atsName) => (
                      <option value={atsName}>
                        {atsName}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {ats === 'Other' && (
                <div className={styles.row}>
                  <TextField
                    className={styles.input}
                    type="text"
                    name="otherAts"
                    label="ATS Name"
                    variant="outlined"
                  />
                </div>
              )}
              <Button
                type="submit"
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </form>
          </Card>
        </Modal>
        <script type="text/javascript">
          {`
            piAId = '824443';
            piCId = '3665';
            piHostname = 'pi.pardot.com';
            
            (function() {
              function async_load(){
                var s = document.createElement('script'); s.type = 'text/javascript';
                s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
              }
              if(window.attachEvent) { window.attachEvent('onload', async_load); }
              else { window.addEventListener('load', async_load, false); }
            })();
          `}
        </script>
      </div>
    </Layout>
  );
};

export default ResourcePartnerProgram;
